html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
  padding: 0;
  margin: 0;
  background: rgb(236, 236, 236);
  color: rgb(66, 66, 66);
  -webkit-tap-highlight-color: rgba(0,0,0,0); 
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

iframe {
  border: 0;
}

.react-swipeable-view-container {
  height: 100% !important;
}
.react-swipeable-view-container > div {
  overflow: auto !important;
  height: 100%;
  z-index: 100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@import "https://fonts.googleapis.com/icon?family=Material+Icons";

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
  padding: 0;
  margin: 0;
  background: rgb(236, 236, 236);
  color: rgb(66, 66, 66);
  -webkit-tap-highlight-color: rgba(0,0,0,0); 
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

iframe {
  border: 0;
}

.react-swipeable-view-container {
  height: 100% !important;
}
.react-swipeable-view-container > div {
  overflow: auto !important;
  height: 100%;
  z-index: 100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.DayPicker {
  display: flex;
  width: 100% !important;
  max-width: 520px !important;
  min-width: 320px !important;
  margin: 0 auto !important;
  position: relative !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.DayPicker_transitionContainer {
  width: 100% !important;
  position: static;
  overflow: unset;
  background: white;
}

.CalendarMonthGrid_month__hidden {
  display: none;
}

.CalendarMonth_table {
  width: 100% !important;
}

.DayPicker_weekHeaders {
  position: unset;
}

.DayPicker_weekHeader {
  display: flex;
  width: 100%;
  padding: 0 !important;
  top: 40px;
}

/* размер названия дней недели */
.DayPicker_weekHeader_ul {
  padding: 0 15px;
  display: flex;
  font-size: 30px;
  max-width: 600px !important;
  min-width: 200px !important;
}

.DayPicker_weekHeader_li {
  width: 84px !important;
  max-width: 85px !important;
  min-width: 10px !important;
}

.CalendarMonth_caption {
  font-size: 20px;
  padding-top: 10px;
  /* padding-bottom: 40px; */
}

.CalendarMonthGrid_month__horizontal {
  width: 100%;
}

.CalendarMonthGrid__horizontal {
  width: 100% !important;
  left: 0;
}

.DayPicker__horizontal {
  width: 100%;
}

.DayPickerNavigation_button__horizontalDefault {
  z-index: 2;
  top: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayPickerNavigation {
  position: unset;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  background: none;
  border: 2px solid #4fc3f7;
}

@media screen and (min-width: 0px) {
  .DayPickerNavigation_button__horizontalDefault {
    z-index: 2;
    top: 5px;
    width: 25px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CalendarMonth_caption {
    padding-top: 10px;
    padding-bottom: 45px;
  }

  .DayPicker_weekHeader {
    display: flex;
    width: 100%;
    padding: 0 !important;
    top: 45px;
  }
}

@media screen and (min-width: 550px) {
  .DayPickerNavigation_button__horizontalDefault {
    width: 30px;
  }
}

@media screen and (min-width: 650px) {
  .DayPickerNavigation_button__horizontalDefault {
    width: 48px;
  }
}

